export namespace Components {
    export interface ILoginButton {
        init();
    }

    export class LoginButton implements ILoginButton {
        loginButton: JQuery;

        constructor(loginButton: JQuery) {
            this.loginButton = loginButton;
        }

        initLoginButton(redirectFunction:any) {
            this.loginButton.on("click",
                (event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    const returnUrl = encodeURIComponent(window.location.href.substring(window.location.origin.length));
                    const baseUrl = this.loginButton.data("href");
                    redirectFunction(`${baseUrl}?redirectUrl=${returnUrl}`);
                });
        }

        init() {
            this.initLoginButton(this.redirect);
        }

        redirect(redirectUrl:string) {
            window.location.href = redirectUrl;
        }
    }
}
